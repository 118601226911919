import { RecebimentoService } from './../../../../services/pcp/recebimento/recebimento.service';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Recebimento } from '../../../../model/pcp/recebimento/recebimento';
import { PaginatorComponent } from "../../../../componentes/paginator/paginator.component";
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { SnackBarService } from '../../../../utils/snack-bar.service';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import { StatusRecebimento } from '../../../../enum/StatusRecebimento.enum';
import { Colors } from 'chart.js';

@Component({
  selector: 'app-pcp-recebimento-consulta',
  standalone: true,
  imports: [CommonModule, PaginatorComponent, FormsModule, ContainerComponentComponent],
  templateUrl: './pcp-recebimento-consulta.component.html',
  styleUrls: ['./pcp-recebimento-consulta.component.scss']
})
export class PcpRecebimentoConsultaComponent implements OnInit {

  paginaAtual: number = 0;
  totalTamanho: number = 0;
  quantidadesPorPagina: any[] = [10, 20, 30, 40, 100]
  totalPorPagina: number = this.quantidadesPorPagina[0];
  listaInspecaoRecebimento: Recebimento[] = [];
  valorDeBusca!: string;
  valorDeBuscaDigitado!: string;
  statusRecebimento = StatusRecebimento;
  status: string = '';
  listaAvalicao = [
    "Todas",
    "Aprovado",
    "Acondicionamento inadequado",
    "Aguardando conferência",
    "Bloqueado engenharia",
    "Bloqueado qualidade",
    "Bloqueado inatividade",
    "Carepas de solda",
    "Chapa preta e corte laser",
    "Em conferência",
    "Etiquetas de lotes anteriores",
    "Embalagem danificada",
    "Embalagem fora de padrão",
    "Embalagem inadequada",
    "Embalagem sem tara",
    "Excesso de óleo",
    "Material sem nota fiscal",
    "Oxidação",
    "Peça amassada",
    "Peça com excesso de sujidade",
    "Peça com graxa",
    "Peça com marcador industrial",
    "Peça com plástico",
    "Peças em excesso",
    "Peças misturadas",
    "Peças riscadas",
    "Parafuso danificado",
    "Quantidade divergente",
    "Resíduo de solda",
    "Resíduo de tinta",
    "Resíduo de chapa preta",
    "Retrabalho",
    "Rosca danificada"]

  constructor(
    private recebimentoService: RecebimentoService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private snackBarService: SnackBarService,
  ) {

  }

  ngOnInit() {
    this.verificarSeTemValorNaRota();
  }

  pegarIcon(recebimento: any): string {
    switch (recebimento.avaliacao) {
      case this.statusRecebimento.BLOQUEADOQUALIDADE:
        return 'fa-solid fa-lock';
      case this.statusRecebimento.SEMNOTA:
        return 'fa-solid fa-barcode';
      case this.statusRecebimento.AGUARDANDOCOFERENCIA:
        return 'fa-solid fa-business-time';
      default:
        return ''; // Caso não precise de ícone, retorna vazio
    }
  }

  public consultarInspecaoRecebimento() {
    this.recebimentoService.consultaInspecaoRecebimento(this.paginaAtual, this.totalPorPagina).subscribe({
      next: (res: any) => {
        this.listaInspecaoRecebimento = res.content;
        this.totalTamanho = res.page.totalElements;
      }, error: (error) => {
        this.snackBarService.tratarErro(error, `Erro ao tentar consultar`);
      }, complete: () => {

      }
    });
  }

  public consultaStatus() {
    if(this.status === 'Todas'){
      this.consultarInspecaoRecebimento();
      return;
    }
    this.recebimentoService.consultaPorStatus(this.paginaAtual, this.totalPorPagina, this.status).subscribe({
      next: (res: any) => {
        this.listaInspecaoRecebimento = res.content;
        this.totalTamanho = res.page.totalElements;
      }, error: (error) => {
        this.snackBarService.tratarErro(error, "Erro ao tentar consultar");
      }, complete: () => {

      }
    }
    );
  }

  public consultaPorFiltro(event?: any) {
    if (!event || event.key == "Enter") {
      if (this.valorDeBusca && this.valorDeBusca.trim() != "") {
        this.recebimentoService.consultaPorFiltro(this.paginaAtual, this.totalPorPagina, this.valorDeBusca).subscribe({
          next: (res: any) => {
            this.listaInspecaoRecebimento = res.content;
            this.totalTamanho = res.page.totalElements;
          }, error: (error) => {
            this.snackBarService.tratarErro(error, "Erro ao tentar consultar");
          }, complete: () => {

          }
        });
      } else {
        this.consultarInspecaoRecebimento();
      }
    }
  }

  public verificarSeTemValorNaRota() {
    this.activatedRoute.params.subscribe({
      next: (res: any) => {
        this.valorDeBusca = res.valor;
        if (this.valorDeBusca) {
          this.consultaPorFiltro();
        } else {
          this.consultarInspecaoRecebimento();
        }
      }
    });
  }

  public editarInspecaoRecebimento(id: number) {
    this.router.navigate([`/pcp/recebimento-registrar/${id}`])
  }



  public onPageChange(event: PageEvent) {
    this.paginaAtual = event.pageIndex;
    this.totalPorPagina = event.pageSize;
    if (this.valorDeBusca) {
        this.consultaPorFiltro();
    } else {
      if(this.status === 'Todas'){
        this.consultarInspecaoRecebimento();
      }else{
        this.consultaStatus();
      }
    }
  }

}
